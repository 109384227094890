import { mapGetters } from 'vuex';

export default {
	name: 'PublicContractResponsibility',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			lang: 'lang/id',
			langPrefix: 'lang/prefix',
		}),
	},
	data() {
		return {
			title: this.lang === 4 ? 'Відповідальність за результати діагностики на СТО' : 'Ответственность за результаты диагностики на СТО',
			description: this.lang === 4 ? 'Мета програми «Перевірте авто» на AUTO.RIA — організація віддаленої перевірки автомобіля: технічної та/або юридичної.' : 'Цель программы «Проверьте авто» на AUTO.RIA — организация удаленной проверки автомобиля: технической и/или юридической.',
		};
	},
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{name: 'description', content: this.description},
				{property: 'og:site_name', content: 'AUTO.RIA'},
				{property: 'og:description', content: this.description},
				{property: 'og:image', content: 'https://auto.ria.com/share_16.png'},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/public-contract/responsibility/'},
				{rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/public-contract/responsibility/'},
				{rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/public-contract/responsibility/'},
				{rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/public-contract/responsibility/'},
				{rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/public-contract/responsibility/'},
				{rel: 'canonical', href: 'https://auto.ria.com/public-contract/responsibility/'}
			]
		};
	}
};
