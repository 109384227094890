const translate = {
	messages: {
		ru: {
			'Ответственность за результаты диагностики на СТО': 'Ответственность за результаты диагностики на СТО',
			'Цель программы «Проверьте авто» на AUTO.RIA — организация удаленной проверки автомобиля: технической и/или юридической.': 'Цель программы «Проверьте авто» на AUTO.RIA — организация удаленной проверки автомобиля: технической и/или юридической.',
			'В рамках организации технической проверки авто менеджер AUTO.RIA обязан:': 'В рамках организации технической проверки авто менеджер AUTO.RIA обязан:',
			'договориться с владельцем автомобиля,': 'договориться с владельцем автомобиля,',
			' заказанного на проверку, о времени проведения диагностики на СТО;': ' заказанного на проверку, о времени проведения диагностики на СТО;',
			'забронировать время для проведения проверки': 'забронировать время для проведения проверки',
			' на СТО-партнёре AUTO.RIA;': ' на СТО-партнёре AUTO.RIA;',
			'принять оплату от заказчика услуги за проведение проверки.': 'принять оплату от заказчика услуги за проведение проверки.',
			' При этом, AUTO.RIA удерживает деньги у себя до получения результатов проверки. Мы гарантируем возврат денег в случае, если автомобиль не прошел проверку (в связи с неявкой владельца авто на проверку, поломкой оборудования или отключение электричества и т.п.), за исключением 7% от общей суммы заказа, которые удерживаются AUTO.RIA за осуществления действий по организации услуги;': ' При этом, AUTO.RIA удерживает деньги у себя до получения результатов проверки. Мы гарантируем возврат денег в случае, если автомобиль не прошел проверку (в связи с неявкой владельца авто на проверку, поломкой оборудования или отключение электричества и т.п.), за исключением 7% от общей суммы заказа, которые удерживаются AUTO.RIA за осуществления действий по организации услуги;',
			'принять от СТО отчёт с результатами диагностики,': 'принять от СТО отчёт с результатами диагностики,',
			' проверить заполнение всех необходимых параметров проверки, отправить отчёт заказчику на электронный адрес и в Личный кабинет на AUTO.RIA.': ' проверить заполнение всех необходимых параметров проверки, отправить отчёт заказчику на электронный адрес и в Личный кабинет на AUTO.RIA.',
			'Обязанности СТО, партнёра программы «Проверьте авто» на AUTO.RIA:': 'Обязанности СТО, партнёра программы «Проверьте авто» на AUTO.RIA:',
			'проведение диагностики': 'проведение диагностики',
			' согласно выбранного пакета диагностических работ;': ' согласно выбранного пакета диагностических работ;',
			'предоставление только достоверных результатов': 'предоставление только достоверных результатов',
			' диагностики о техническом состоянии автомобиля.': ' диагностики о техническом состоянии автомобиля.',
			'Гарантии': 'Гарантии',
			'Вы защищены от форс-мажорных обстоятельств и оплачиваете проверку существующего автомобиля от реального продавца. ': 'Вы защищены от форс-мажорных обстоятельств и оплачиваете проверку существующего автомобиля от реального продавца. ',
			'AUTO.RIA гарантирует возврат денег': 'AUTO.RIA гарантирует возврат денег',
			' в случае, если автомобиль не прошел проверку, кроме 7% от общей суммы заказа, которые удерживаются AUTO.RIA за осуществление действий по организации услуги.': ' в случае, если автомобиль не прошел проверку, кроме 7% от общей суммы заказа, которые удерживаются AUTO.RIA за осуществление действий по организации услуги.',
			'Форс-мажорными обстоятельствами,': 'Форс-мажорными обстоятельствами,',
			' при которых заказчику возвращаются деньги за заказанную услугу проверки, считаются:': ' при которых заказчику возвращаются деньги за заказанную услугу проверки, считаются:',
			'поломка оборудования на СТО;': 'поломка оборудования на СТО;',
			'отключение электричества на СТО;': 'отключение электричества на СТО;',
			'отказ от проверки владельцем автомобиля;': 'отказ от проверки владельцем автомобиля;',
			'ДТП и т.д.': 'ДТП и т.д.',
			'Оплата перечисляется сайту AUTO.RIA.com за официально предоставляемую услугу, при этом вы получаете чек или банковскую выписку, которую необходимо сохранить.': 'Оплата перечисляется сайту AUTO.RIA.com за официально предоставляемую услугу, при этом вы получаете чек или банковскую выписку, которую необходимо сохранить.',
			'Вы можете связаться с непосредственным исполнителем и оперативно уточнить интересующие вас вопросы.': 'Вы можете связаться с непосредственным исполнителем и оперативно уточнить интересующие вас вопросы.',
			' В отчете с результатами диагностики технического состояния авто указываются контактные данные СТО и имя мастера, проводившего диагностику.': ' В отчете с результатами диагностики технического состояния авто указываются контактные данные СТО и имя мастера, проводившего диагностику.',
			'С каждой партнёрской СТО у нас заключён договор': 'С каждой партнёрской СТО у нас заключён договор',
			' о предоставлении только достоверной информации о состоянии автомобиля. За нарушение данного условия, AUTO.RIA исключает СТО из списка партнёров программы «Перевірте авто».': ' о предоставлении только достоверной информации о состоянии автомобиля. За нарушение данного условия, AUTO.RIA исключает СТО из списка партнёров программы «Перевірте авто».',
			'Ответственность': 'Ответственность',
			'Согласно п.2.1.4 публичного соглашения, размещенного на сайте AUTO.RIA.com по адресу ': 'Согласно п.2.1.4 публичного соглашения, размещенного на сайте AUTO.RIA.com по адресу ',
			'  мы гарантируем возврат денег в случае, если проверка по каким-то причинам не была проведена.': '  мы гарантируем возврат денег в случае, если проверка по каким-то причинам не была проведена.',
			'Согласно п. 4.2 публичного соглашения размещенного на AUTO.RIA.com по адресу ': 'Согласно п. 4.2 публичного соглашения размещенного на AUTO.RIA.com по адресу ',
			' общество с ограниченной ответственностью «Интернет Реклама РИА» является посредником и не несет ответственности за качество услуг и достоверность результатов, предоставленных проверяющей СТО, а также за сохранение состояния транспортного средства с момента проверки и до момента продажи.': ' общество с ограниченной ответственностью «Интернет Реклама РИА» является посредником и не несет ответственности за качество услуг и достоверность результатов, предоставленных проверяющей СТО, а также за сохранение состояния транспортного средства с момента проверки и до момента продажи.',
			'Ответственность за результаты диагностики несет СТО, предоставляющее услугу проверки.': 'Ответственность за результаты диагностики несет СТО, предоставляющее услугу проверки.'
		},
		uk: {
			'Ответственность за результаты диагностики на СТО': 'Відповідальність за результати діагностики на СТО',
			'Цель программы «Проверьте авто» на AUTO.RIA — организация удаленной проверки автомобиля: технической и/или юридической.': 'Мета програми «Перевірте авто» на AUTO.RIA — організація віддаленої перевірки автомобіля: технічної та/або юридичної.',
			'В рамках организации технической проверки авто менеджер AUTO.RIA обязан:': 'В рамках організації технічної перевірки авто менеджер AUTO.RIA зобов\'язаний:',
			'договориться с владельцем автомобиля,': 'домовитися з власником автомобіля,',
			' заказанного на проверку, о времени проведения диагностики на СТО;': ' замовленого на перевірку, про час проведення діагностики на СТО;',
			'забронировать время для проведения проверки': 'забронювати час для проведення перевірки',
			' на СТО-партнёре AUTO.RIA;': ' на СТО-партнері AUTO.RIA;',
			'принять оплату от заказчика услуги за проведение проверки.': 'прийняти оплату від замовника послуги за проведення перевірки.',
			' При этом, AUTO.RIA удерживает деньги у себя до получения результатов проверки. Мы гарантируем возврат денег в случае, если автомобиль не прошел проверку (в связи с неявкой владельца авто на проверку, поломкой оборудования или отключение электричества и т.п.), за исключением 7% от общей суммы заказа, которые удерживаются AUTO.RIA за осуществления действий по организации услуги;': ' При цьому, AUTO.RIA утримує гроші у себе до отримання результатів перевірки. Ми гарантуємо повернення грошей у разі, якщо автомобіль не пройшов перевірку (у зв\'язку з неявкою власника авто на перевірку, поломкою обладнання або відключення електрики, і т.п.), за винятком 7% від загальної суми замовлення, які утримуються AUTO.RIA за здійснення дій по організації послуги;',
			'принять от СТО отчёт с результатами диагностики,': 'прийняти від СТО звіт з результатами діагностики,',
			' проверить заполнение всех необходимых параметров проверки, отправить отчёт заказчику на электронный адрес и в Личный кабинет на AUTO.RIA.': ' перевірити заповнення всіх необхідних параметрів перевірки, відправити звіт замовникові на електронну адресу та в Особистий кабінет на AUTO.RIA.',
			'Обязанности СТО, партнёра программы «Проверьте авто» на AUTO.RIA:': 'Обов\'язки СТО, партнера програми «Перевірте авто» на AUTO.RIA:',
			'проведение диагностики': 'проведення діагностики',
			' согласно выбранного пакета диагностических работ;': ' згідно обраного пакету діагностичних робіт;',
			'предоставление только достоверных результатов': 'надання тільки достовірних результатів',
			' диагностики о техническом состоянии автомобиля.': ' діагностики про технічний стан автомобіля.',
			'Гарантии': 'Гарантії',
			'Вы защищены от форс-мажорных обстоятельств и оплачиваете проверку существующего автомобиля от реального продавца. ': 'Ви захищені від форс-мажорних обставин і оплачуєте перевірку існуючого автомобіля від реального продавця. ',
			'AUTO.RIA гарантирует возврат денег': 'AUTO.RIA гарантує повернення коштів',
			' в случае, если автомобиль не прошел проверку, кроме 7% от общей суммы заказа, которые удерживаются AUTO.RIA за осуществление действий по организации услуги.': ' у разі, якщо автомобіль не пройшов перевірку, крім 7% від загальної суми замовлення, які утримуються AUTO.RIA за здійснення дій по організації послуги.',
			'Форс-мажорными обстоятельствами,': 'Форс-мажорними обставинами,',
			' при которых заказчику возвращаются деньги за заказанную услугу проверки, считаются:': ' при яких замовнику повертаються гроші за замовлену послугу перевірки, вважаються:',
			'поломка оборудования на СТО;': 'поломка обладнання на СТО;',
			'отключение электричества на СТО;': 'відключення електрики на СТО;',
			'отказ от проверки владельцем автомобиля;': 'відмова від перевірки власником автомобіля;',
			'ДТП и т.д.': 'ДТП і т.д.',
			'Оплата перечисляется сайту AUTO.RIA.com за официально предоставляемую услугу, при этом вы получаете чек или банковскую выписку, которую необходимо сохранить.': 'Оплата перераховується сайту AUTO.RIA.com за офіційно надану послугу, при цьому ви отримуєте чек або банківську виписку, яку необхідно зберегти.',
			'Вы можете связаться с непосредственным исполнителем и оперативно уточнить интересующие вас вопросы.': 'Ви можете зв\'язатися з безпосереднім виконавцем і оперативно уточнити ваші запитання.',
			' В отчете с результатами диагностики технического состояния авто указываются контактные данные СТО и имя мастера, проводившего диагностику.': ' У звіті з результатами діагностики технічного стану авто вказуються контактні дані СТО та ім\'я майстра, який проводив діагностику.',
			'С каждой партнёрской СТО у нас заключён договор': 'З кожної партнерської СТО у нас укладений договір',
			' о предоставлении только достоверной информации о состоянии автомобиля. За нарушение данного условия, AUTO.RIA исключает СТО из списка партнёров программы «Перевірте авто».': ' про надання тільки достовірної інформації про стан автомобіля. За порушення цієї умови, AUTO.RIA виключає СТО зі списку партнерів програми «Перевірте авто».',
			'Ответственность': 'Відповідальність',
			'Согласно п.2.1.4 публичного соглашения, размещенного на сайте AUTO.RIA.com по адресу ': 'Згідно п.2.1.4 публічної угоди, розміщеної на сайті AUTO.RIA.com за адресою ',
			'  мы гарантируем возврат денег в случае, если проверка по каким-то причинам не была проведена.': ' ми гарантуємо повернення грошей у разі, якщо перевірка з будь-яких причин не була проведена.',
			'Согласно п. 4.2 публичного соглашения размещенного на AUTO.RIA.com по адресу ': 'Згідно п.4.2 публічної угоди розміщеної на AUTO.RIA.com за адресою ',
			' общество с ограниченной ответственностью «Интернет Реклама РИА» является посредником и не несет ответственности за качество услуг и достоверность результатов, предоставленных проверяющей СТО, а также за сохранение состояния транспортного средства с момента проверки и до момента продажи.': ' товариство з обмеженою відповідальністю «Інтернет Реклама РІА» є посередником і не несе відповідальності за якість послуг і достовірність результатів, наданих перевіряючою СТО, а також за збереження стану транспортного засобу з моменту перевірки і до моменту продажу.',
			'Ответственность за результаты диагностики несет СТО, предоставляющее услугу проверки.': 'Відповідальність за результати діагностики несе СТО, яка надає послугу перевірки.'
		}
	}
};
export default translate;
